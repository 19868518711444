<template>
  <div class="categories-permissions">
    <div class="categoryName">
      {{ categoryRestrictions.categoryName }}
    </div>

    <div
      class="subcategories-permissions"
      v-for="subcategoryRestriction in categoryRestrictions.subcategoryRestriction"
      :key="subcategoryRestriction.subcategoryId"
    >
      <div class="subcategory-name">
        {{ subcategoryRestriction.subcategoryName }}
      </div>
      <div class="restriction-options">
        <BaseSelect
          solo
          required
          hide-details
          item-text="text"
          item-value="id"
          :value="subcategoryRestriction.restriction"
          :items="subcategoriesPermissionsDataSource"
          :disabled="!canEdit"
          @input="
            (value) => onRestrictionValueChange(subcategoryRestriction, value)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { subcategoriesRestrictions } from "@/utils/GlobalRestrictionCodes";

export default {
  name: "CategoryPermissions",
  props: {
    canEdit: { type: Boolean, default: true },
    categoryRestrictions: { type: Object, default: () => {} },
  },
  data() {
    return {
      subcategoriesPermissionsDataSource: [
        {
          id: subcategoriesRestrictions.none,
          text: this.$t("roles.permission_none"),
        },
        {
          id: subcategoriesRestrictions.read,
          text: this.$t("roles.permission_read"),
        },
        {
          id: subcategoriesRestrictions.readWrite,
          text: this.$t("roles.permission_readWrite"),
        },
      ],
    };
  },
  methods: {
    onRestrictionValueChange(subcategoryRestriction, value) {
      const newCategoryRestrictions = { ...this.categoryRestrictions };

      const newSubcategoryRestriction =
        newCategoryRestrictions.subcategoryRestriction.find(
          (item) => item.subcategoryId === subcategoryRestriction.subcategoryId
        );

      if (newSubcategoryRestriction) {
        newSubcategoryRestriction.restriction = value;

        this.$emit("onChange", newCategoryRestrictions);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.categories-permissions {
  margin-top: 30px;

  .categoryName {
    color: $text-title;
    font-size: 18px;
  }

  .subcategories-permissions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    .subcategory-name {
      width: 40%;
    }

    .restriction-options {
      width: 60%;
      margin-left: 10px;
    }
  }
}
</style>
