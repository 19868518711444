<template>
  <div ref="container">
    <ModalWindow ref="confirmModal" />
    <slot></slot>
  </div>
</template>

<script>
import ModalWindow from "@/components/Shared/ModalWindow";

const closeBeforeEvent = "beforeunload";

export default {
  name: "DraftGuard",
  components: { ModalWindow },
  props: {
    workInProgress: {
      required: true,
      type: Boolean,
      default: () => false,
    },
  },
  beforeDestroy() {
    this.disposeAll();
  },
  data() {
    return {
      disposeOldRouteGuard: () => {},
    };
  },
  methods: {
    _onBrowserCloseHandler(e) {
      e.preventDefault();
      e.returnValue = "";
    },
    _onRouteBeforeHandler(to, from, next) {
      if (from.fullPath === this.$route.fullPath) {
        const modalOptions = { persistent: true };

        this.$refs.confirmModal
          .open(
            this.$t("global.confirm").toUpperCase(),
            this.$t("common.closeConfirm"),
            modalOptions
          )
          .then((result) => {
            if (result) {
              next();
            }
          });
      } else {
        next();
      }
    },
    disposeAll() {
      window.removeEventListener(closeBeforeEvent, this._onBrowserCloseHandler);
      this.disposeOldRouteGuard();
    },
    registerAll() {
      window.addEventListener(closeBeforeEvent, this._onBrowserCloseHandler);
      this.disposeOldRouteGuard = this.$router.beforeEach(
        this._onRouteBeforeHandler
      );
    },
  },
  watch: {
    workInProgress: {
      handler: function (currentValue) {
        if (currentValue) {
          this.registerAll();
        } else {
          this.disposeAll();
        }
      },
      immediate: true,
    },
  },
  render() {
    return this.$scopedSlots.default ? this.$scopedSlots.default() : null;
  },
};
</script>
