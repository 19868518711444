<template>
  <DraftGuard :work-in-progress="editionMode">
    <div>
      <ModalDialog
        v-model="isModalShow"
        :header="header"
        @confirm="onSave"
        @close="onClose"
        @open="onOpen"
        @cancel="onCancel"
        :persistent="editionMode"
        :editMode="editionMode"
      >
        <template #activator="{}">
          <div v-on:click="isModalShow = true">
            <slot name="button"></slot>
          </div>
        </template>
        <template slot="actions">
          <v-btn
            v-can:CFG_WRITE
            v-if="!editionMode && allowEdit"
            @click="isEditing = true"
            large
            icon
            text
          >
            <IconBox :tooltip="roleText.editRole" color="iconButton">
              stem-edit
            </IconBox>
          </v-btn>
          <v-btn
            v-if="id && allowEdit"
            v-can:CFG_WRITE
            @click="isConfirmDeleteModalShown = true"
            large
            icon
            text
          >
            <IconBox :tooltip="roleText.deleteRole" color="iconButton">
              stem-delete
            </IconBox>
          </v-btn>
        </template>
        <v-container slot="content">
          <BaseInput
            :flat="!editionMode"
            :readonly="!editionMode"
            :name="roleText.roleName"
            required
            v-model="roleName"
            :error-messages="errorMessages.roleName"
            @input="$v.roleName.$touch()"
            @blur="$v.roleName.$touch()"
          ></BaseInput>

          <div class="categories-permissions-section">
            <p class="textColor--text">
              {{ `${$t("roles.subcategoriesPermissions")}:` }}
            </p>
            <CategoryPermissions
              v-for="restriction in categoriesRestrictions"
              :key="restriction.categoryId"
              :categoryRestrictions="restriction"
              :canEdit="allowEdit && editionMode"
              @onChange="onCategoryPermissionChange"
            />
          </div>

          <div class="modules-permissions-section">
            <p class="textColor--text">{{ $t("roles.modulesPermissions") }}</p>
            <TreeList
              selectable
              showExpandButton
              :disabled="!allowEdit || !editionMode"
              :nodes="items"
              :values="selectedRestrinctions"
              @input="onTreeValueChange"
              :key="treeListComponentKey"
            />
          </div>

          <div class="logout-section" v-if="isRestrictionChange">
            <div class="header">{{ $t("common.warning") }}</div>
            <v-checkbox
              :label="$t('roles.forceLogoutAfterChange')"
              v-model="requireLogout"
            />
          </div>
        </v-container>
      </ModalDialog>
      <ConfirmModal
        v-model="isConfirmDeleteModalShown"
        @confirm="deleteRole()"
        @cancel="isConfirmDeleteModalShown = false"
      />
      <ModalWindow ref="confirmModal" />
    </div>
  </DraftGuard>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { mapActions, mapState } from "vuex";

import RolesService from "@/services/RolesService.js";

import { subcategoriesRestrictions } from "@/utils/GlobalRestrictionCodes";
import { required } from "vuelidate/lib/validators";
import validationThings from "@/mixins/validation-things";
import EventBus, { EmitError, EmitSuccess } from "@/eventBus.js";

import ModalDialog from "../Shared/ModalDialog";
import TreeList from "@/components/Shared/TreeList";
import CategoryPermissions from "./CategoryPermissions";
import ConfirmModal from "@/components/Shared/ConfirmModal";
import DraftGuard from "../DraftGuard";
import ModalWindow from "@/components/Shared/ModalWindow";

export default {
  name: "AddOrEditRole",
  components: {
    DraftGuard,
    ModalDialog,
    TreeList,
    ConfirmModal,
    ModalWindow,
    CategoryPermissions,
  },
  mixins: [validationThings],
  props: {
    name: {
      type: String,
    },
    id: {
      type: Number,
      default: 0,
    },
    alreadyUsedNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isConfirmDeleteModalShown: false,
      isModalShow: false,
      roleText: this.$t("roles"),
      roleName: this.name,
      isEditing: false,
      items: [],
      initialData: {},
      categoriesRestrictions: [],
      selectedRestrinctions: [],
      errorMessages: {},
      requireLogout: false,
      isRestrictionChange: false,
      treeListComponentKey: 0,
    };
  },
  validations: {
    roleName: {
      required,
    },
  },
  methods: {
    ...mapActions("settings", ["fetchUserRestrictions"]),
    onOpen() {
      this.getRestrictionsForRole();
      this.roleName = this.name;
      this.isRestrictionChange = false;
      this.requireLogout = false;
    },
    onClose() {
      this.errorMessages = {};
      this.isEditing = false;
      this.isRestrictionChange = false;
      this.requireLogout = false;
      this.$v.$reset();

      if (this.isNew) {
        this.restoreInitialData();
        this.isModalShow = false;
      }
    },
    onCancel() {
      this.$refs.confirmModal
        .open(
          this.$t("global.confirm").toUpperCase(),
          this.$t("common.closeConfirm")
        )
        .then((result) => {
          if (result) {
            if (!this.isNew) {
              this.restoreInitialData();
            }
            this.onClose();
          }
        });
    },
    onSave() {
      if (this.editionMode) {
        const validationErrors = this.getValidationErrorsMessages();
        if (validationErrors.length > 0) {
          validationErrors.forEach((validationError) => {
            EmitError(validationError);
          });
          return;
        }
      }

      if (!this.$v.$invalid && this.editionMode) {
        if (this.isNew) {
          this.addRole();
        } else {
          this.editRole();
        }
      }
    },
    getRestrictionsForRole() {
      return RolesService.GetRestrictionsForRole(this.id).then((response) => {
        if (response) {
          this.initialData = cloneDeep(response);
          this.initialData.roleName = this.roleName;
          this.categoriesRestrictions = [...response.categoriesRestriction];
          const modulesRestrictions = [...response.restrictions];
          this.mapRestrictionsToNodes(modulesRestrictions);
        }
      });
    },
    addRole() {
      RolesService.AddRoleWithRestrictions({
        id: this.id,
        name: this.roleName,
        categoriesRestriction: this.categoriesRestrictions,
        restrictions: this.selectedRestrinctions.map((item) => {
          return { id: item };
        }),
      })
        .then((res) => {
          EmitSuccess(this.$t("roles.addSuccess"));
          this.$emit("onSave", { res });
          this.isModalShow = false;
        })
        .catch(() => EmitError(this.$t("roles.addError")))
        .finally(() => {
          this.onClose();
        });
    },
    editRole() {
      RolesService.EditRoleWithRestrictions({
        id: this.id,
        name: this.roleName,
        categoriesRestriction: this.categoriesRestrictions,
        restrictions: this.selectedRestrinctions.map((item) => {
          return { id: item };
        }),
        requireLogout: this.requireLogout,
      })
        .then((res) => {
          EmitSuccess(this.$t("roles.editSuccess"));
          this.$emit("onSave", { res });
          this.isModalShow = false;
          this.initialData.roleName = this.roleName;
          if (this.user) {
            this.fetchUserRestrictions({
              userId: this.user.userId,
            });
          }
        })
        .finally(() => {
          this.onClose();
        });
    },
    deleteRole() {
      this.isConfirmDeleteModalShown = false;

      RolesService.DeleteRoles([this.id])
        .then(() => {
          EmitSuccess(this.$t("roles.deleteRoleSuccess"));
          EventBus.$emit("eb-update-role-list");
          this.isModalShow = false;
        })
        .finally(() => {
          this.onClose();
        });
    },
    mapRestrictionsToNodes(nodeItems) {
      this.items = [];

      nodeItems.forEach((item) => {
        if (item.checked && item.parentId) {
          this.selectedRestrinctions.push(`${item.id}`);
        }

        if (!item.parentId) {
          const node = {
            id: item.id,
            name: item.name,
            parentId: item.parentId,
            children: [],
            locked: false,
          };
          this.items.push(node);
        }

        if (item.parentId) {
          const parent = this.items.find((x) => x.id === item.parentId);
          if (parent) {
            parent.children.push({
              id: item.id,
              name: item.name,
              locked: false,
            });
          }
        }
      });
    },
    onTreeValueChange(newValues) {
      this.selectedRestrinctions = newValues;
    },
    onCategoryPermissionChange(newCategoryRestrictions) {
      const newPermissions = [];

      this.categoriesRestrictions.forEach((restriction) => {
        if (restriction.categoryId === newCategoryRestrictions.categoryId) {
          const categoryPermission = {
            ...restriction,
            subcategoryRestriction: [
              ...newCategoryRestrictions.subcategoryRestriction,
            ],
          };
          newPermissions.push(categoryPermission);
        } else {
          newPermissions.push(restriction);
        }
      });

      this.categoriesRestrictions = [...newPermissions];
    },
    restoreInitialData() {
      const initialData = cloneDeep(this.initialData);
      this.selectedRestrinctions = [];
      this.errorMessages = {};
      this.isRestrictionChange = false;
      this.requireLogout = false;
      this.treeListComponentKey = this.treeListComponentKey + 1;

      this.roleName = initialData.roleName;
      this.categoriesRestrictions = [...initialData.categoriesRestriction];
      const modulesRestrictions = [...initialData.restrictions];
      this.mapRestrictionsToNodes(modulesRestrictions);
    },
    getValidationErrorsMessages() {
      const messages = [];

      Object.keys(this.errorMessages).forEach((itemKey) => {
        if (this.errorMessages[itemKey] != null) {
          messages.push(this.errorMessages[itemKey]);
        }
      });

      if (
        this.isNew &&
        this.selectedRestrinctions.length === 0 &&
        this.categoriesRestrictions.every((category) =>
          category.subcategoryRestriction.every(
            (subcategory) =>
              subcategory.restriction === subcategoriesRestrictions.none
          )
        ) &&
        this.roleName === undefined
      ) {
        messages.push(this.$t("roles.permissionAndRoleValidation"));
      } else if (this.roleName === undefined || this.roleName === "") {
        messages.push(this.$t("roles.roleValidation"));
      } else if (this.isNew && this.selectedRestrinctions.length === 0) {
        messages.push(this.$t("roles.minPermissionValidation"));
      }

      return messages;
    },
  },
  computed: {
    ...mapState("oidcStore", ["user"]),
    isNew() {
      return this.id <= 0;
    },
    header() {
      if (this.isNew) {
        return this.$t("roles.newRole");
      } else {
        return this.$t("roles.details");
      }
    },
    editionMode() {
      return (this.isEditing || this.isNew) && this.isModalShow;
    },
    allowEdit() {
      return !(this.id === 1 && this.roleName === "Administrator");
    },
  },
  watch: {
    roleName(newValue) {
      if (newValue !== this.initialData.roleName) {
        this.errorMessages.roleName = this.alreadyUsedNames.some(
          (usedName) =>
            usedName.toLowerCase() === (newValue || "").trim().toLowerCase()
        )
          ? "Nazwa roli musi być unikalna"
          : null;
      }
    },
    categoriesRestrictions(newValues) {
      const nodeItems = cloneDeep(this.items);

      if (
        newValues.some((category) =>
          category.subcategoryRestriction.some(
            (subcategory) =>
              subcategory.restriction !== subcategoriesRestrictions.none
          )
        )
      ) {
        nodeItems[1].children[2].locked = true;
        if (!this.selectedRestrinctions.includes("403")) {
          this.selectedRestrinctions.push("403");
        }
      } else {
        nodeItems[1].children[2].locked = false;
      }

      this.items = nodeItems;

      if (!this.isNew && this.editionMode) {
        this.isRestrictionChange = true;
      }
    },
    selectedRestrinctions() {
      if (!this.isNew && this.editionMode) {
        this.isRestrictionChange = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modules-permissions-section {
  margin-top: 50px;
}

.logout-section {
  margin-top: 25px;

  .header {
    color: $text-title;
    font-size: 16px;
  }
}
</style>
