<template>
  <DataGrid
    :card="card"
    v-model="selectedRoles"
    :display.sync="display"
    :search="searchText"
    @update:search="searchHandler"
    :items="roles"
    :pagination="pagination"
    @update:pagination="getRoles"
    :isLoading="isLoading"
    :minimumCardWidth="425"
    :alreadyUsedNames="roles.map((item) => item.name)"
  >
    <template #header-buttons>
      <div class="header-buttons">
        <v-btn
          class="header-navigation-button"
          router
          rounded
          text
          v-can:CFG_WRITE
          :to="{ name: 'users' }"
        >
          {{ $t("objects.users") }}
        </v-btn>
        <v-btn
          rounded
          color="navigationPageButtons"
          class="header-navigation-button white--text"
          v-can:CFG_WRITE
        >
          {{ $t("objects.roles") }}
        </v-btn>
      </div>
    </template>
    <template #header>
      <div class="header" v-bind:class="isMobile && 'mobile'">
        <OrderPicker :options="orderByOptions" v-model="orderBy" />
        <FiltersPicker :categories="filterOptions" v-model="filters" />
      </div>
    </template>

    <template #toolbar>
      <v-spacer></v-spacer>
      <AddOrEditRole
        v-can:CFG_WRITE
        :alreadyUsedNames="roles.map((item) => item.name)"
        @onSave="onSave"
      >
        <v-btn large icon text slot="button" class="toolbar-buttons">
          <IconBox :tooltip="$t('roles.newRole')" color="iconButton">
            stem-rolePlus
          </IconBox>
        </v-btn>
      </AddOrEditRole>
      <ConfirmModal
        v-model="isConfirmDeleteModalShown"
        :confirmText="
          $t('global.confirmTextCount', {
            count: selectedRoles ? selectedRoles.length : 0,
          })
        "
        @confirm="deleteRoles"
        @cancel="isConfirmDeleteModalShown = false"
      ></ConfirmModal>
      <v-btn
        large
        icon
        text
        class="toolbar-buttons"
        :disabled="!selectedRoles || selectedRoles.length === 0"
        @click="tryDelete"
      >
        <IconBox :tooltip="$t('roles.deleteRole')" color="iconButton">
          stem-delete
        </IconBox>
      </v-btn>
    </template>
  </DataGrid>
</template>

<script>
import RolesService from "../services/RolesService.js";
import DataGrid from "../components/Shared/DataGrid";
import RoleCard from "../components/Roles/RoleCard";
import AddOrEditRole from "../components/Roles/AddOrEditRole";
import FiltersPicker from "../components/Shared/FiltersPicker";
import OrderPicker from "../components/Shared/OrderPicker";
import ConfirmModal from "../components/Shared/ConfirmModal";
import { mapProps } from "@/store";
import EventBus, { EmitSuccess } from "@/eventBus.js";
import { GetErrorData, GetErrorMsg } from "@/utils/ServerErrorHandler.js";
import { mapGetters } from "vuex";

export default {
  components: {
    DataGrid,
    AddOrEditRole,
    FiltersPicker,
    OrderPicker,
    ConfirmModal,
  },
  data() {
    return {
      isLoading: false,
      card: RoleCard,
      roles: [],
      orderByOptions: ["NAME"],
      filterOptions: [],
      restrictions: [],
      isConfirmDeleteModalShown: false,
      searchText: "",
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([this.getRestrictions()])
      .then(() => {
        this._setFilters();
        this.getRoles();
      })
      .catch(() => (this.isLoading = false));
  },
  mounted() {
    EventBus.$on("eb-update-role-list", this.updateRoleListener);
  },
  destroyed() {
    EventBus.$off("eb-update-role-list", this.updateRoleListener);
  },
  methods: {
    updateRoleListener() {
      this.getRoles();
    },
    getRestrictions() {
      return RolesService.GetParentRestrictions().then((response) => {
        this.restrictions = response;
      });
    },
    getRoles(pagination) {
      return RolesService.GetRoles({
        ...this.filtersDto,
        ...this._getPagination(pagination),
      })
        .then((res) => {
          res.data.forEach((d) => (d.error = ""));
          this.roles = res.data;
          this.pagination = {
            ...this.pagination,
            page: res.pagination.currentPage,
            itemsPerPage: res.pagination.itemsPerPage,
            itemsLength: res.pagination.totalItems,
            pageStart:
              (res.pagination.currentPage - 1) * res.pagination.itemsPerPage,
            pageStop: res.pagination.currentPage * res.pagination.itemsPerPage,
            pageCount: Math.ceil(
              res.pagination.totalItems / res.pagination.itemsPerPage
            ),
          };
        })
        .finally(() => (this.isLoading = false));
    },
    // eslint-disable-next-line no-unused-vars
    onSave(item) {
      this.getRoles();
    },
    deleteRoles() {
      this.isConfirmDeleteModalShown = false;

      RolesService.DeleteRoles(this.selectedRoles.map((x) => x.id))
        .then(() => {
          EmitSuccess(this.$t("roles.deleteSuccess"));
          this.selectedRoles = [];
          this.getRoles();
        })
        .catch((err) => {
          const errorData = GetErrorData(err);
          if (errorData && errorData.errors) {
            Object.entries(errorData.errors).forEach(([id, value]) => {
              const role = this.selectedRoles.find((r) => r.id === +id);
              if (role) role.error = GetErrorMsg(value.errorCode);
            });
          }
        });
    },
    tryDelete() {
      if (this.selectedRoles.length > 0) this.isConfirmDeleteModalShown = true;
    },
    _getPagination(pagination = this.pagination) {
      return {
        pageNumber: pagination.page,
        pageSize: pagination.itemsPerPage,
      };
    },
    searchHandler(search) {
      this.pagination.page = 1;
      this.searchText = search;
    },
    formatFilters() {
      if (Object.entries(this.filters).length > 0) {
        return {
          restrictions: [...this.filters.restrictions],
        };
      }
      return null;
    },
    _setFilters() {
      const options = [
        {
          type: "multiSelect",
          name: this.$t("orderBy.MODULE"),
          value: "restrictions",
          options: this.restrictions.map((g) => {
            return { name: g.text, value: g.id };
          }),
        },
      ];
      this.filterOptions.push(...options);
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    ...mapProps(
      ["orderBy", "filters", "display", "pagination", "selectedRoles"],
      {
        options: "roleOptions",
        action: "saveRoleOption",
      }
    ),
    filtersDto() {
      return {
        orderBy: this.orderBy.name,
        orderDesc: this.orderBy.desc,
        search: this.searchText,
        ...this.formatFilters(),
      };
    },
  },
  watch: {
    filtersDto: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.pagination.page = 1;
        this.getRoles();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.header-buttons {
  .header-navigation-button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

.toolbar-buttons {
  margin-right: 10px;
  font-size: 12px;
}
</style>
