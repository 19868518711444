<template>
  <!-- Tile -->
  <AddOrEditRole
    :name="roleName"
    :id="id"
    :alreadyUsedNames="alreadyUsedNames"
    @onSave="onSave"
  >
    <template slot="button">
      <div v-if="display === displayListTypes.CARDS" class="tile-wrapper">
        <v-card
          class="tile"
          :flat="!isSelected"
          height="180"
          :class="{ errorBorder: error }"
          :ripple="false"
        >
          <div class="tile-content">
            <div class="tile-content-details">
              <div class="content-header">
                <div class="icon">
                  <IconBox color="iconButton" :tooltip="$t('user.roles')">
                    stem-role
                  </IconBox>
                </div>
                <TooltipTruncate :attrs="'subheading bold'">
                  {{ roleName }}
                </TooltipTruncate>
              </div>

              <v-layout wrap align-start justify-start fill-height>
                <v-flex xs6 v-for="item in roleRestrictions" :key="item.id">
                  <TooltipTruncate>{{ item.name }}</TooltipTruncate>
                </v-flex>
              </v-layout>
            </div>

            <div class="tile-content-buttons">
              <div class="select-field" v-if="allowEdit">
                <v-checkbox
                  color="fields"
                  :input-value="isSelected"
                  @click.stop.prevent="$emit('select')"
                />
              </div>
            </div>
          </div>
        </v-card>
        <span class="error--text error-message" v-if="error">{{ error }}</span>
      </div>

      <!-- Inline -->
      <div v-else class="inline-wrapper">
        <v-card
          :flat="!isSelected"
          :class="{ errorBorder: error }"
          class="inline"
        >
          <v-card-text>
            <div class="inline-content">
              <v-layout
                overflow-hidden
                justify-space-between
                align-center
                class="inline-content-details textColor--text"
              >
                <v-flex xs2>
                  <v-layout overflow-hidden justify-start align-center>
                    <IconBox
                      color="iconButton"
                      :styles="{ 'margin-right': '5px' }"
                      :tooltip="$t('user.roles')"
                    >
                      stem-role
                    </IconBox>
                    <TooltipTruncate :attrs="'subheading bold'">
                      {{ roleName }}
                    </TooltipTruncate>
                  </v-layout>
                </v-flex>
                <v-flex xs9>
                  <span class="label">{{ $t("roles.permissions") }}</span>
                  <TooltipTruncate>
                    {{ roleRestrictions.map((x) => x.name) | join }}
                  </TooltipTruncate>
                </v-flex>
              </v-layout>

              <div class="inline-content-buttons">
                <div class="select-field" v-if="allowEdit">
                  <v-checkbox
                    color="fields"
                    :input-value="isSelected"
                    @click.stop.prevent="$emit('select')"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <span class="error--text error-message" v-if="error">{{ error }}</span>
      </div>
    </template>
  </AddOrEditRole>
</template>

<script>
import AddOrEditRole from "./AddOrEditRole";
import DisplayListTypes from "@/models/displayListTypes.js";

export default {
  components: {
    AddOrEditRole,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    restrictions: {
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    display: {
      type: String,
      default: DisplayListTypes.CARDS,
    },
    color: {
      type: String,
      default: "grey",
    },
    error: {
      type: String,
      default: "",
    },
    alreadyUsedNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      roleName: this.name,
      roleRestrictions: this.restrictions,
      displayListTypes: DisplayListTypes,
    };
  },
  methods: {
    onSave(item) {
      this.roleName = item.res.name;
      this.roleRestrictions = item.res.restrictions;
    },
  },
  computed: {
    allowEdit() {
      return !(this.id === 1 && this.roleName === "Administrator");
    },
  },
};
</script>

<style scoped lang="scss">
.tile:hover,
.inline:hover {
  background: $card-hover;
}

.tile-wrapper,
.inline-wrapper {
  box-shadow: $card-shadow;
}

.tile {
  .tile-content {
    padding: 16px;

    .tile-content-details {
      min-height: 100px;

      .content-header {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        .icon {
          margin-right: 10px;
        }
      }
    }

    .tile-content-buttons {
      display: flex;
      margin-top: 10px;
      height: 30px;

      .select-field {
        margin-left: auto;

        .v-input--selection-controls.v-input {
          margin: 0;
        }
      }
    }
  }
}

.inline {
  .inline-content {
    display: flex;
    flex-direction: row;

    .inline-content-details {
      .label {
        color: $label;
      }
    }
  }
}

.error-message {
  font-size: 13px;
}

.errorBorder {
  box-shadow: inset 0 0 0 2px $error;
}
</style>
