import axios, { paramsSerializer } from "../plugins/http";
const apiUrl = "api/roles";

export default {
  GetRoles(params) {
    return axios.get(apiUrl, {
      params,
      paramsSerializer,
    });
  },
  GetRestrictionsForRole(id) {
    return axios.get(`${apiUrl}/${id}`);
  },
  EditRoleWithRestrictions(roleModel) {
    return axios.put(apiUrl, roleModel);
  },
  AddRoleWithRestrictions(roleModel) {
    return axios.post(apiUrl, roleModel);
  },
  DeleteRoles(ids) {
    return axios.delete(apiUrl, {
      data: ids,
    });
  },
  GetParentRestrictions() {
    return axios.get(`${apiUrl}/parentRestrictions`);
  },
};
