<template>
  <v-dialog v-model="isShown" :persistent="persistent" :max-width="maxWidth">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>

    <v-card>
      <v-card-text>
        <v-container grid-list-lg>
          <v-layout
            id="modal-header-layout"
            v-bind:class="isMobile && 'mobile'"
          >
            <v-flex ml-3 align-self-center>
              <div class="header">
                {{ header.toUpperCase() }}
              </div>
            </v-flex>
            <v-flex shrink>
              <slot name="actions">
                <small>{{ textsCommon.fieldsRequired }}</small>
              </slot>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <slot name="content"></slot>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="buttons-footer">
          <div class="edit-buttons" v-if="editMode">
            <v-btn
              color="roundButton roundButtonText--text"
              rounded
              @click="$emit('cancel')"
              class="cancel-button"
            >
              {{ cancelText || textsCommon.cancel }}
            </v-btn>
            <v-btn
              color="roundButton roundButtonText--text"
              rounded
              @click="$emit('confirm')"
              :loading="isLoading"
            >
              {{ confirmText || textsCommon.save }}
            </v-btn>
          </div>
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click="isShown = false"
            v-else
          >
            {{ textsCommon.close }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalMixin from "@/mixins/modalMixin.js";
import { mapGetters } from "vuex";

export default {
  mixins: [ModalMixin],
  name: "ModalDialog",
  props: {
    header: String,
    confirmText: String,
    cancelText: String,
    maxWidth: {
      type: String,
      default: "800px",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      textsCommon: this.$t("common"),
    };
  },
  methods: {
    onClosed() {
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
  },
  watch: {
    isShown(newValue) {
      if (newValue) {
        this.$emit("open");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  margin-top: 20px;
  color: $text-title;
  font-weight: 300;
  font-size: 20px;
}

.buttons-footer {
  margin-bottom: 20px;
  margin-left: auto;

  .cancel-button {
    margin-right: 10px;
  }
}
</style>
